<template>
    <transition name="slide-down-fade" appear>       
        <div class="">
            <div class="col-md-3 " style="padding-left: 25px; padding-right: 25px;" v-for="e in datosBeneficios" :key="e.id">
                <div class="thumbnail benef-config"  style="box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); borderColor:#BFBFBF; borderWidth:0px; padding: 10% 10% 10% 10%; border-radius: 0px;">
                    <div class="caption" style="">
                        <h5 class="card-title text-center ">{{ e.title }}</h5>
                        <p class="card-text" v-html="e.txt"></p>                        
                    </div>
                </div>
            </div>
        </div>
    </transition>        
</template>

<script>
import getCatAcercade from '@/helpers/getCatAcercade'

export default {
    data() {
        return { 
            datosBeneficios: [],
        }
    },
    methods: {
        async getAcercade() {
            const dataAcercade = await getCatAcercade()
            const arrShow = []
            
            if (dataAcercade[0].bt1_titulo.length > 0){
                this.cant_box += 1;
                arrShow.push(
                    {
                       
                        title: `${ dataAcercade[0].bt1_titulo }`,
                        txt: `${ dataAcercade[0].bt1_desc }`
                       
                    },           
                )
            }        
            if (dataAcercade[0].bt2_titulo.length > 0){
                this.cant_box += 1;
                arrShow.push(
                    {
                        
                        title: `${ dataAcercade[0].bt2_titulo }`,
                        txt: `${ dataAcercade[0].bt2_desc }`
                        
                    }           
                )
            }        
            if (dataAcercade[0].bt3_titulo.length > 0 ){
                this.cant_box += 1;
                arrShow.push(
                    {
                       
                        title: `${ dataAcercade[0].bt3_titulo }`,
                        txt: `${ dataAcercade[0].bt3_desc }`
                       
                    }           
                )
            }
            if (dataAcercade[0].bt4_titulo.length > 0 ){
                this.cant_box += 1;
                arrShow.push(
                    {
                       
                        title: `${ dataAcercade[0].bt4_titulo }`,
                        txt: `${ dataAcercade[0].bt4_desc }`
                       
                    }           
                )
            }        
        
            if (this.cant_box==1){
                this.col_sm='col-sm-12 col-md-12';
            }
            if (this.cant_box==2){
                this.col_sm='col-sm-6 col-md-6';
            }
            if (this.cant_box==3){
                this.col_sm='col-sm-4 col-md-4';
            }
            if (this.cant_box==4){
                this.col_sm='col-sm-3 col-md-3';
            }

            return arrShow
        }
    },
    async mounted() {
        this.datosBeneficios = await this.getAcercade()
        window.scrollTo(0, 0)
    }
}
</script>

<style>
    .slide-down-fade-enter-from {  /*Fade de isquierda a derecha*/
        opacity: 0;
        /*transform: translateY(-130px)*/
        transform: translateX(-250%)
    }
        .slide-down-fade-enter-active {
        transition: all 5s ease
    }

    .card-title {
        text-transform: uppercase;
    }
     


/* SECCION PARA RESPONSIVIDAD DE LA PLATAFORMA */
        @media (min-width:320px)  { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
        .benef-config {
            height: 380px;
            

        }  
            


        }
        @media (min-width:480px)  { 
        /* smartphones, Android phones, landscape iPhone */ 
        .benef-config {
            height: 370px;

        }  
           

        }
        @media (min-width:600px)  {  /* esta medida es la que da problemas */
        /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
        .benef-config {
            height: 310px;

        }  
            

        }
        @media (min-width:801px)  { 
        /* tablet, landscape iPad, lo-res laptops ands desktops */ 
        .benef-config {
            height: 460px;

        }  
            
        
        }
        @media (min-width:1025px) { 
        /* big landscape tablets, laptops, and desktops */ 
        .benef-config {
            height: 500px;

        }  
           
        }
        @media (min-width:1281px) { 
        /* hi-res laptops and desktops */
        .benef-config {
            height: 350px;
            

        }  

        }


        
</style>