<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el logotipo de la Organización? </h5>
            <h6> {{ datosOrg_logo }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getOrg_logoById from '@/helpers/getOrg_logoById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosOrg_logo: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
//            const delItem = await updateDataByIdCol( 'org_logos', charsService.decode(this.$route.params.id), jsonData )
            await updateDataByIdCol( 'org_logos', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getOrg_logo() {
            const dataorg_logo = await getOrg_logoById(charsService.decode( this.$route.params.id ))

            const cadena = `${ dataorg_logo.nom_organizacion },  ${ dataorg_logo.url_organizacion }`;

            return cadena
        }
    },
    async mounted() {
        this.datosOrg_logo = await this.getOrg_logo();
        // this.getOrg_logo()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>