<template>
    
        <div class="row bg-light ">
            <div class="col-lg-12" style="background-color:#ffffff" >
                <div class="col-md-12"  style="">
                    <div class="article-body" >
                        <p class="card-text text-center" style="" v-html="this.contenido_organizacioones"></p>
                    </div>
                </div>

                <div class="col-lg-12" style="background-color: #ffffff; ">
                    <div class="col-md-3" style=""  v-for="(h) in arrInformes" :key="h.id">
                        
                            <button class="btn"  style="background-color: #ffffff;  height: 220px;"> 
                                <div class="carousel-caption" style=" box-shadow: 0 1px 1px 3px rgba(0,0,0,0.08), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.16), 0 8px 8px rgba(0,0,0,0.20);"> 
                                    <a :href="h.url_organizacion" alt="logo" target="_blank" > 
                                        <img style=" height: 100%; object-fit: cover;" :src= "h.ImagenUrl"  alt="Logo"  >
                                    </a>

                                </div> 
                            </button>
                            
                        
                        
                    </div>
                </div>
                <div class="col-lg-12">
                    <br><br><br>
                </div>
            </div>
        </div>
        <div class="col-lg-12" style="height: 200px;"></div>
</template>

<script>
import getAcercadeById from '@/helpers/getAcercadeById'
import getOrg_logos from '@/helpers/getOrg_logos'
import { ubApi } from '@/api/baseUrl'
import { url } from '@/api/baseUrl'
//import charsService from '@/services/CharsService';

export default {
    //url_imagen:'',
    data() {
        return { 
            ubApi: ubApi.value,
            url: url.value,
            contenido_organizacioones: '',
            arrInformes: [],
        }
    },
    methods: {
        async getCat_Acercade() {
            const datos = await getAcercadeById(  localStorage.getItem("Nidioma") )
            this.contenido_organizacioones = datos.contenido_organizacioones;
            return
        },

        async getAllEventosxMun() {
          const informesArr = await getOrg_logos()
          const arrShow = []

          for (let i=0; i<informesArr.length; i++) {

                const ImagenUrl =  this.url + '/uploads/' + `${ informesArr[i].imagen_url }`
                const url_organizacion =  `${ informesArr[i].url_organizacion }`

                arrShow.push({
                        ImagenUrl,
                        url_organizacion,
                })
          }
          return arrShow
        },
    },
    async mounted() {
        await this.getCat_Acercade();
        //this.datosBienvenida = await this.getConoceDerechos()
        this.arrInformes = await this.getAllEventosxMun()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>

    
/*   seccion de sombreado de la Imagen */
    .carousel-caption {
        color: #000000;
        background-color:#ffffff;
        left:5px;
        top: 5px;
        width: 90%;
        height: 90%;
        position: absolute;
        z-index: 100;
       
    }

</style>