<template>
     <h4 class="mt-0">Agregar un logotipo de Organización</h4>
     <hr>
     <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre de la Organización:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idInforme" name="idInforme" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_organizacion }" id="nom_organizacion" name="nom_organizacion" placeholder="Nombre de la Organización" v-model="form.nom_organizacion" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="url_organizacion">Página web de la Organización:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="url_organizacion" name="url_organizacion" placeholder="En formato https:// o http://" v-model="form.url_organizacion" />
                    </div>
                </div>


                <!--------------------- seccion para la imagen ---------------------------------->
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_url">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_url" name="imagen_url" placeholder="Nombre en el server" v-model="form.imagen_url" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_nombre">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_nombre" name="imagen_nombre" placeholder="nombre del archivo" v-model="form.imagen_nombre" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_extension" name="imagen_extension" placeholder="extensión" v-model="form.imagen_extension" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir la imagen:<br>(En formato PNG y tamaño 250 pixeles ancho x 250 pixeles altura)</label>
                    <div class="col-md-7">
                        <div v-if="successAlertImg" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlertImg=false">&times;</a>
                            {{ successMessageImg }}
                        </div>
                        <div v-if="errorAlertImg" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlertImg=false">&times;</a>
                            {{ errorMessageImg }}
                        </div>
                        <input type="file" ref="imagen" />
                        <br>
                        <button type="button" @click="uploadImagen" class="btn btn-default btn-sm">Subir imagen</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre de la imagen</th>
                                 
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.imagen_url }&fileName=${ form.imagen_nombre }`" target="_blank" title="Descargar"> {{ form.imagen_nombre }}</a></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>






                <!--------------------- seccion para el archivo ---------------------------------->
                <div hidden class="form-group row">
                    <label class="col-md-4" to="tmp_name">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="tmp_name" name="tmp_name" placeholder="Nombre en el server" v-model="form.tmp_name" />
                    </div>
                </div>
                <div hidden class="form-group row">
                    <label class="col-md-4" to="file_name">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="file_name" name="file_name" placeholder="nombre del archivo" v-model="form.file_name" />
                    </div>
                </div>
                <div hidden class="form-group row">
                    <label class="col-md-4" to="extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="extension" name="extension" placeholder="extensión" v-model="form.extension" />
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateInforme" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateInforme" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro logotipo" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addOrg_logos" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>



</template>


<script>
import axios from 'axios'

import addData from '@/helpers/addData'
import getDataByAnyOrg_logo from '@/helpers/getDataByAnyOrg_logo'
import { ubApi } from '@/api/baseUrl'

export default {
    data() {
        return {
            ubApi: ubApi.value,
            
            imagen: '',
            successAlertImg: false,
            errorMessageImg: '',
            successMessageImg: '',
            errorAlertImg: false,

            form: {
                nom_organizacion: '',
                url_organizacion: '',

                imagen_url: '',
                imagen_nombre: '',
                imagen_extension: '',

            },
            iconImg: '',

            noValid: {
                nom_organizacion: false,
                url_organizacion: false,

                imagen_url: false,
                imagen_nombre: false,
                imagen_extension: false,

            },
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_organizacion = ''
            this.form.url_organizacion = ''

            this.form.imagen_url = ''
            this.form.imagen_nombre = ''
            this.form.imagen_extension = ''

            this.added = false
        },
        async addOrg_logos() {
            this.noValid.nom_organizacion = ( this.form.nom_organizacion )? false : true
            this.noValid.url_organizacion = ( this.form.url_organizacion )? false : true

            this.noValid.imagen_url = ( this.form.imagen_url )? false : true
            this.noValid.imagen_nombre = ( this.form.imagen_nombre )? false : true
            this.noValid.imagen_extension = ( this.form.imagen_extension )? false : true


            const valnom_organizacion = await getDataByAnyOrg_logo( 'org_logos', 'nom_organizacion', this.form.nom_organizacion );
            
            if ( valnom_organizacion.length < 1 ){
                if ( !this.noValid.nom_organizacion) {
                    if ( !this.noValid.url_organizacion) {
                        if ( !this.noValid.imagen_url) {
                    
                            const dataForm = {
                                nom_organizacion: this.form.nom_organizacion,
                                url_organizacion: this.form.url_organizacion,
                                
                                imagen_url: this.form.imagen_url,
                                imagen_nombre: this.form.imagen_nombre,
                                imagen_extension: this.form.imagen_extension,

                            }
                            const jsonData = JSON.parse(JSON.stringify( dataForm ))
                            const addOrg_logos = await addData('org_logos', jsonData)
                            if ( addOrg_logos[0].res.status === 200 ) {
                                this.$notify({
                                    title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                                });
                                this.added = true
                            } else {
                                this.$notify({
                                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                                });
                            }


                        } else {
                            this.$notify({
                                title: 'Error', text: 'Favor de subir la imagen', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Favor de capturar la Url de la Organización', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la Organización', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una Organización registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        },


        async uploadImagen() {
            this.imagen = this.$refs.imagen.files[0]
            var formDataImg = new FormData()
            formDataImg.append('file', this.imagen)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formData, {
            await axios.post( ubApi.value + '/uploadFiles.php', formDataImg, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message

                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlertImg = z_errorAlert
            this.successAlertImg = z_successAlert
            this.errorMessageImg = z_errorMessage
            this.successMessageImg = z_successMessage
            this.$refs.imagen.value = z_fileValue

            if (z_successAlert) {
                // AvanceAcciones módulo para tabla de archivos
//                const jsonInsFile = JSON.parse(JSON.stringify({
//                    "imagen_url": z_tmpFileName,
//                    "imagen_nombre": z_baseName,
//                    "imagen_extension": z_extension
//                }))
                this.form.imagen_url=z_tmpFileName
                this.form.imagen_nombre=z_baseName
                this.form.imagen_extension=z_extension
            }
        },
    }
}
</script>
<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

</style>