<template>

        <div class="content" style="width: 100%; overflow: hidden;">
            <div class="row snap-container" >            
            <!-------------------------------ACERCA DE----------------------------->
            <!--<div class="col-lg-12 thumbnail"  style="box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); borderColor:#BFBFBF; borderWidth:0px; padding: 0px 0px 0px 0px; border-radius: 0px;">-->
                <div class="col-lg-12 snap-section"  style="background-color: #ffffff;">
                    
                                    
                    <div class="">
                        <div class="card-header panel-acerca-sombra" style="margin-top: 12%;"> <!--position:fixed;-->
                            <br><br>

                                
                                <div class="col-lg-2"> 
                                    
                                </div>

                                <div class="col-lg-10">

                                
                                    <div class="col-md-10"  style="">
                                        <div class="card-header" >
                                            <p class="card-text txt-tp" v-html="this.acercade_titulo"></p>
                                        </div>
                                    </div>

                                    <div class="col-md-9"  style=""> 
                                        <div class="" >
                                            <p class="card-text txt-p1" v-html="this.acercade_descripcion"></p>
                                        </div>
                                    </div>


                                    <div class="col-md-9"  style=""> 
                                        <div class="" >
                                            <p class="card-text txt-p2" v-html="this.acercade_contenido"></p>
                                        </div>
                                    </div>
                                </div>
                                


                        </div>
                    </div>

                </div>

                        <!-------------------------------BENEFICIOS-------------------------->
          
                
                <div class="col-lg-12 snap-section"  style="background-color: #ffffff; ">
                    
                    <div class="card-header " style="height: 100%;" >
                        
                            <div class="col-md-12 "  style="margin-top: 12%;">
                                <div class="article-body">
                                        
                                        <p class="card-text" style="padding-left: 20px;" v-html="this.beneficios_titulo"></p>
                                        <AcercadeBeneficios/>
                                        
                                </div>
                                
                            </div>
                            
                    </div>
                    
                </div>
                <!-------------------------------LIMITACIONES-------------------------->
                    
                <div class="col-lg-12 snap-section" style="background-color: #ffffff;  display: flex; justify-content: center;">
                        <div class="col-md-8" style="margin-top: 12%;">
                            <div class="card-header " style="padding: 0px 10px 0px 10px;">
                                <p class="card-text text-center" style="color: rgb(0, 0, 0);" v-html="this.limitaciones_contenido"></p>
                            </div>
                        </div>
                </div>
                    
                    
                <!--------------------------LOGOS DE ORGANIZACIONES-------------------------->
                <div class="col-lg-12 snap-section panel-org-sombra" style="background-color: #ffffff;  ">
                    <div class="card-header " style="margin-top: 10%; display: flex; justify-content: center; ">
                        <div class="col-md-8 ">
                            <Logos_organizaciones/>
                        </div>    
                    </div>    
                </div>    

                <!------------------------------ENVIO DE CORREO------------------------------>

                <br><br><br><br><br>
                <div class="col-lg-12 snap-section" style="background-color: #7B62D9;">
                    <div class="card-header" style="margin-top: 12%; display: flex; background-color: #7B62D9; display: flex; justify-content: center; color: white;">
                        <div class="col-md-8 " style="margin-top: 5%; margin-bottom: 3%;">
                            <EnvioCorreo/>
                        </div>    
                    </div>
                </div>
                <br>   

               
            </div>

        </div>

</template>

<script>
//import getCatAcercade from '@/helpers/getCatAcercade'
import getAcercadeById from '@/helpers/getAcercadeById'
import AcercadeBeneficios from '@/components/Acercade/AcercadeBeneficios'
import Logos_organizaciones from '@/components/Acercade/Logos_organizaciones'
import EnvioCorreo from '@/components/Acercade/EnvioCorreo'


export default {
    components: {
       AcercadeBeneficios,
       Logos_organizaciones,
        EnvioCorreo
    },
    data() {
        return { 
            acercade_titulo: '',
            acercade_descripcion: '',
            acercade_contenido: '',
            beneficios_titulo: '',
            limitaciones_contenido: '',
//            contenido_organizacioones: '',
        }
    },
    methods: {
        async getCat_Acercade() {
            const datos = await getAcercadeById(  localStorage.getItem("Nidioma") )
           
            this.acercade_titulo = datos.acercade_titulo;
            this.acercade_descripcion = datos.acercade_descripcion;
            this.acercade_contenido = datos.acercade_contenido;
            this.beneficios_titulo = datos.beneficios_titulo;
            this.limitaciones_contenido = datos.limitaciones_contenido;
//            this.contenido_organizacioones = datos.contenido_organizacioones;
           
            return
        },
    },
    async mounted() {
        await this.getCat_Acercade();
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
    .snap-container {
        height: 96vh;
        overflow: auto;
        scroll-snap-type: y mandatory;  
    }
    .snap-section {
        scroll-snap-align: start;
        min-height: 96vh;
    }

    input {
        padding: 5px 40px;
        margin: 25px 0 50px;
        text-align: justify;
    }
    .article-body {
        text-align: justify;
    }
    .pantalla-fija {
        position:fixed;
    }
   
    main pp {        /**borrar este opcion emt */
        position: sticky;
        top:80px;
        border:10px solid #1B75CE;
        display:inline-block;
    }

             
    
/* SECCION PARA RESPONSIVIDAD DE LA PLATAFORMA */
        @media (min-width:320px)  { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            .panel-acerca-sombra {
                /*margin-top: 70px;
                display: flex; 
                height: 90%;
                */
                justify-content: center; 
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .panel-org-sombra {
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .txt-tp {
                font-size: 5vw;  
            }
            .txt-p1 {
               font-size: 5vw; 
            }
            .txt-p2 {
               font-size: 5vw; 
            }


        }
        @media (min-width:480px)  { 
        /* smartphones, Android phones, landscape iPhone */ 
            .panel-acerca-sombra {
                /*margin-top: 70px;
                display: flex; 
                height: 90%;
                */
                justify-content: center; 

                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .panel-org-sombra {
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .txt-tp {
                font-size: 3.5vw;  
            }
            .txt-p1 {
               font-size: 3.5vw; 
            }
            .txt-p2 {
               font-size: 3.5vw; 
            }

        }
        @media (min-width:600px)  {  /* esta medida es la que da problemas */
        /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
            .panel-acerca-sombra {
                /*margin-top: 130px;
                display: flex; 
                height: 90%;
                */
                justify-content: center; 

                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .panel-org-sombra {
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .txt-tp {
                font-size: 2.7vw;  
            }
            .txt-p1 {
               font-size: 2.7vw; 
            }
            .txt-p2 {
               font-size: 2.7vw; 
            }

        }
        @media (min-width:801px)  { 
        /* tablet, landscape iPad, lo-res laptops ands desktops */ 
            .panel-acerca-sombra {
                /*margin-top: 200px;
                display: flex; 
                height: 90%;
                */
                justify-content: center; 
                
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .panel-org-sombra {
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .txt-tp {
                font-size: 2.1vw;  
            }
            .txt-p1 {
               font-size: 2.1vw; 
            }
            .txt-p2 {
               font-size: 2.1vw; 
            }
        
        }
        @media (min-width:1025px) { 
        /* big landscape tablets, laptops, and desktops */ 
            .panel-acerca-sombra {
                /*margin-top: 200px;
                display: flex; 
                height: 90%;
                */
                justify-content: center; 
                
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .panel-org-sombra {
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .txt-tp {
                font-size: 1.8vw;  
            }
            .txt-p1 {
               font-size: 1.8vw; 
            }
            .txt-p2 {
               font-size: 1.8vw; 
            }
        }
        @media (min-width:1281px) { 
        /* hi-res laptops and desktops */
            .panel-acerca-sombra {
                /*margin-top: 200px;
                display: flex; 
                height: 90%;
                */
                justify-content: center; 
                
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .panel-org-sombra {
                box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 50px 100px -20px rgba(50, 50, 93, 0), 0px 30px 60px -30px rgba(0, 0, 0, 0.3); 
            }
            .txt-tp {
                font-size: 1.2vw;  
            }
            .txt-p1 {
               font-size: 1.2vw; 
            }
            .txt-p2 {
               font-size: 1.2vw; 
            }

        }




</style>