<template>
    <!-- TODO: Ajustar nuevo submenú -->
    <nav class="navbar navbar-inverse sub-navbar navbar-fixed-top">
        
        <!-- Idiomas -->
        <NavbarIdiomas />  
        <!-- Menu principal -->
        
        
        
        
        <div class="franja-mp-config" style="background-color: #542EA5;">    <!--  La classe container pone padding al Menu -->
            <div class="navbar-header" style=" height: 100%; background-color: #542EA5;">
                <a href="/">
                    <img class="logo-config" src= "@/assets/images/logo_cc_small.png" alt="Logo">
                </a>  
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#MenuPrincipal" style="background-color: #542EA5;">
                    <span class="sr-only">Interruptor de Navegación</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
               
            </div>
            <div class="collapse navbar-collapse" id="MenuPrincipal" style="background-color:#542EA5;">    <!--   rgba(0,0,0, .0)-->
                
                <!--<div class="col-md-12" style="display: flex; justify-content: center;">
                    <div class="col-md-6"  style="">
                        <br>
                    </div>
                    <div class="col-md-6 gradient-txt"  style="background-color: aqua; "> 
                -->
                            <ul class="nav navbar-nav navbar-right navbar-margen" style="">
                                <li class="nav-item" style="">
                                    <router-link class="nav-link subnav-link persSJ" style="padding-top: 0px; height:30px; margin-top: 0px; margin-right:10px;  border-radius: 10px; background-color: #542EA5; color:#fff;" to="/" title="Página Principal de Cuidando el Camino"><p style="margin-top: 35px;" v-html="this.Gbtn_inicio_desc"></p></router-link>  <!--542EA5-->
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link subnav-link persSJ" style="padding-top: 0px; height:30px; margin-top: 0px; margin-right:10px; border-radius: 10px; background-color: #542EA5; color: #fff;" to="/acercade" title="Acerca de"><p style="margin-top: 35px;" v-html="this.Gbtn_acerca_desc"></p></router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link class="nav-link subnav-link persSJ" style="padding-top: 0px; height:30px; margin-top: 0px; margin-right:10px; border-radius: 10px; background-color: #542EA5; color: #fff;" to="/servicioresp" title="Mapa del directorio de servicios"><p style="margin-top: 35px;" v-html="this.Gbtn_directorio_desc"></p></router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link subnav-link persSJ" style="padding-top: 0px; height:30px; margin-top: 0px; margin-right:10px; border-radius: 10px; background-color: #542EA5; color: #fff;" to="/conocetusderechos"  title="Información sobre tus derechos"><p style="margin-top: 35px;" v-html="this.Gbtn_informacion_desc"></p></router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link subnav-link persSJ" style="padding-top: 0px; height:30px; margin-top: 0px; margin-right:10px; border-radius: 10px; background-color: #542EA5; color: #fff;" :to="`/adminSec`"  title="Administración de la plataforma"><p style="margin-top: 35px;" v-html="this.Gbtn_registro_desc"></p></router-link>
                                </li>
                            </ul>
               <!-- </div>        
                </div>-->

            </div>
        </div>
    </nav>
</template>

<script>
import charsService from '@/services/CharsService'
import NavbarIdiomas from '@/components/Common/NavbarIdiomas';
import getCat_MenuPaginaById from '@/helpers/getCat_MenuPaginaById'

export default {
    components: {
        NavbarIdiomas,
    },    
    props: {
        user: Boolean
    },
    data() {
        return {
            Gbtn_inicio_desc: localStorage.getItem("Gbtn_inicio_desc"),
            
            Gbtn_acerca_desc: localStorage.getItem("Gbtn_acerca_desc"),

            Gbtn_directorio_desc: localStorage.getItem("Gbtn_directorio_desc"),
            Gbtn_informacion_desc: localStorage.getItem("Gbtn_informacion_desc"),
            Gbtn_registro_desc: localStorage.getItem("Gbtn_registro_desc"),
            
            userId: charsService.decode(localStorage.getItem('id')),
            userIdEnc: localStorage.getItem('id'),
            logged: this.user
        }
    },
    methods: {
        async valida_menuPagina() {
            //alert (localStorage.getItem("Gbtn_inicio_desc"))
            //if (!localStorage.getItem("Gbtn_inicio_desc")){
                //alert (datos.btn_acerca_desc)
                const datos = await getCat_MenuPaginaById(  localStorage.getItem("Nidioma") )
                localStorage.setItem("Gbtn_inicio_desc", datos.btn_inicio_desc)
                localStorage.setItem("Gbtn_acerca_desc", datos.btn_acerca_desc)
                localStorage.setItem("Gbtn_directorio_desc", datos.btn_directorio_desc)
                localStorage.setItem("Gbtn_informacion_desc", datos.btn_informacion_desc)
                localStorage.setItem("Gbtn_registro_desc", datos.btn_registro_desc)
            //}
            this.Gbtn_inicio_desc = localStorage.getItem("Gbtn_inicio_desc")
            this.Gbtn_acerca_desc = localStorage.getItem("Gbtn_acerca_desc")
            this.Gbtn_directorio_desc = localStorage.getItem("Gbtn_directorio_desc")
            this.Gbtn_informacion_desc = localStorage.getItem("Gbtn_informacion_desc")
            this.Gbtn_registro_desc = localStorage.getItem("Gbtn_registro_desc")
            return
        },
    },  
    mounted() {
        if ( localStorage.getItem('nombre') ) {
            this.logged = true
            this.$route.params.tUser = this.userId
        }
        this.valida_menuPagina()
        //alert (localStorage.getItem("Gbtn_inicio_desc"))
        
        
    }
}
</script>

<style>
    .sub-navbar {
        height: 50px;
    }

    .persSJ {
        height: 70px;
        display: flex !important;
        align-items: center;
    }

    .breadcrumb {
        margin-top: 40px;
    }

    /* SECCION PARA RESPONSIVIDAD DE LA PLATAFORMA */
        @media (min-width:320px)  { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            .franja-mp-config {
                height: 80px; 
            }
            .navbar-margen{
                margin-top: 5px;
                margin-right: 0px;
                background-color: #542EA5;
            }  
        }
        @media (min-width:480px)  { 
        /* smartphones, Android phones, landscape iPhone */ 
            .franja-mp-config {
                height: 80px; 
            }
            .navbar-margen{
                margin-top: 5px;
                margin-right: 0px;
                background-color: #542EA5;
            } 

        }
        @media (min-width:600px)  { 
        /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
            .franja-mp-config {
                height: 110px; 
            }
            .navbar-margen{
                margin-top: 40px;
                margin-right: 20px;
                background-color: rgb(255, 255, 255, .0); 
            }
        
        }
        @media (min-width:801px)  { 
        /* tablet, landscape iPad, lo-res laptops ands desktops */ 
            .franja-mp-config {
                height: 110px; 
            }
            .navbar-margen{
                margin-top: 40px;
                margin-right: 20px;
                background-color: rgb(255, 255, 255, .0); 
            }
        }
        @media (min-width:1025px) { 
        /* big landscape tablets, laptops, and desktops */ 
            .franja-mp-config {
                height: 110px; 
            }
            .navbar-margen{
                margin-top: 40px;
                margin-right: 20px;
                background-color: rgb(255, 255, 255, .0); 
            }  

        }
        @media (min-width:1281px) { 
        /* hi-res laptops and desktops */
            .franja-mp-config {
                height: 110px; 
            }

            .navbar-margen{
                margin-top: 40px;
                margin-right: 20px;
                background-color: rgb(255, 255, 255, .0); 
            }  

        }
</style>