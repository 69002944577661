/* Regresa los datos de org_logos conforme al id enviado*/

import simejuvApi from '../api/simejuvApi'

const getOrg_logoById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/org_logos/${ id }`)
    ]

    const [ logos ] = await Promise.all( promiseArr )

    return logos.data
}

export default getOrg_logoById